.core-create-list-of-employee-role-list-item-button-remove {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0.25rem;
}

@media only screen and (max-width: 480px) {
    .core-create-list-of-employee-use-system {
        position: initial;
        margin-top: 0px;
    }    
    .core-create-list-of-employee-button-close {
        display: none !important;
    }

    .core-create-list-of-employee-role-list-item-organization {
        margin-top: 0.25rem !important;
    }
  }