@media only screen and (max-width: 480px) {
    .core-create-new-electronic-report {
        flex-direction: column;
    }

    .core-create-new-electronic-report-filter {
        width: 100% !important;
        border: none;
        padding-right: 0px;
    }

    .core-create-new-electronic-report-list {
        width: 100% !important;
        padding-left: 0px;
    }
}