@media only screen and (max-width: 480px) {
    .core-report-personal-sumwork-filter {
        flex-direction: column;
    }    

    .core-report-personal-sumwork-filter .core-input-resize {
        width: 100% !important;
    }    

    .core-report-personal-sumwork-filter .core-datetime-picker-resize {
        width: 100% !important;
    }
  }