@media only screen and (max-width: 480px) {
    .core-report-personal-redwork {
        flex-direction: column;
    }    

    .core-report-personal-redwork .core-input-resize {
        width: 100% !important;
    }    

    .core-report-personal-redwork .core-button-resize {
        width: 100% !important;
    }
  }