@media only screen and (max-width: 480px) {
    .core-report-sumwork-filter {
        flex-direction: column;
    }    

    .core-report-sumwork-filter .core-datetime-picker-resize {
        width: 100% !important;
    }

    .core-report-sumwork-filter .core-select-resize {
        width: 100% !important;
    }
  }