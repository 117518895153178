@media only screen and (max-width: 480px) {
    .core-create-or-update-setting-proceduce-step-item-header-content {
       flex-direction: column !important;
    }

    .core-create-or-update-setting-proceduce-step-item-header-content-function {
        justify-content: space-between;
        margin-top: 0.25rem;
    }

    .core-create-or-update-setting-proceduce-step-item-type-group {
        flex-direction: column !important;
    }

    .core-create-or-update-setting-proceduce-button-close {
        display: none;
    }
}