@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&display=swap");

:root {
  --view-height: 100vh;
  --scale: 100;
  --px-4: calc(4px * var(--scale) / 100);
  --px-6: calc(6px * var(--scale) / 100);
  --px-8: calc(8px * var(--scale) / 100);
  --px-10: calc(10px * var(--scale) / 100);
  --px-12: calc(12px * var(--scale) / 100);
  --px-14: calc(14px * var(--scale) / 100);
  --px-16: calc(16px * var(--scale) / 100);
  --px-18: calc(18px * var(--scale) / 100);
  --px-20: calc(20px * var(--scale) / 100);
  --px-24: calc(24px * var(--scale) / 100);
  --px-28: calc(28px * var(--scale) / 100);
  --px-30: calc(30px * var(--scale) / 100);
  --px-32: calc(32px * var(--scale) / 100);
  --px-36: calc(36px * var(--scale) / 100);
  --px-48: calc(48px * var(--scale) / 100);
  --px-60: calc(60px * var(--scale) / 100);
  --px-150: calc(150px * var(--scale) / 100);
  --px-180: calc(180px * var(--scale) / 100);
  --px-240: calc(240px * var(--scale) / 100);
  --px-380: calc(380px * var(--scale) / 100);
  --px-460: calc(460px * var(--scale) / 100);
  --px-640: calc(640px * var(--scale) / 100);
  --px-710: calc(710px * var(--scale) / 100);
  --px-820: calc(820px * var(--scale) / 100);
  --px-970: calc(970px * var(--scale) / 100);
  --px-1100: calc(1100px * var(--scale) / 100);
  --px-1200: calc(1200px * var(--scale) / 100);
  --shadow-1: 0px 0px 2px 0px rgb(196 196 196 / 30%),
    0px 1px 3px 1px rgba(60, 64, 67, 0.15);
  --shadow-5: 0px 4px 4px 0px rgba(60, 64, 67, 0.3),
    0px 8px 12px 6px rgba(60, 64, 67, 0.15);
  --s-8: calc(8px * var(--scale) / 100) / calc(11px * var(--scale) / 100) -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --s-10: calc(10px * var(--scale) / 100) / calc(13px * var(--scale) / 100) -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --s-12: calc(12px * var(--scale) / 100) / calc(15px * var(--scale) / 100) -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --s-14: calc(14px * var(--scale) / 100) / calc(15px * var(--scale) / 100) -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --s-15: calc(15px * var(--scale) / 100) / calc(19px * var(--scale) / 100) -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --s-16: calc(16px * var(--scale) / 100) / calc(19px * var(--scale) / 100) -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --s-18: calc(18px * var(--scale) / 100) / calc(22px * var(--scale) / 100) -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
}

code {
  font-family: "Be Vietnam Pro", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus,
select:focus,
textarea:focus {
  outline-width: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Written by Mr.Thinh */
input,
select {
  height: auto !important;
  padding: 8px;
  border-radius: 4px;
}

input[type="checkbox"] {
  height: 2rem !important;
}

.footer {
  border-top: 1px solid #d1d1d1;
}

.footer button {
  padding: 8px 16px !important;
  height: auto;
  font-size: 15px;
  font-weight: 500;
  border-radius: 4px;
}

.button-no-padding {
  padding: 0 !important;
}

button svg+p {
  padding-left: 6px;
}

html {
  overflow: hidden;
  height: 100%;
}

.css-wsp0cs-MultiValueGeneric {
  white-space: normal !important;
}

#optionListScroll::-webkit-scrollbar-track {
  background: white !important;
}

#optionListScroll.hide-scroll::-webkit-scrollbar-thumb {
  background-color: white !important;
}

.diagram-line {
  display: block !important;
}

.diagram-element-animation {
  -webkit-animation: opacityDiagramElementAnimation 300ms ease-out;
  -moz-animation: opacityDiagramElementAnimation 300ms ease-out;
  -o-animation: opacityDiagramElementAnimation 300ms ease-out;
  animation: opacityDiagramElementAnimation 300ms ease-out;
}

@-webkit-keyframes opacityDiagramElementAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes opacityDiagramElementAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes opacityDiagramElementAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes opacityDiagramElementAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* input {
  position: relative;
}

input:before {
  position: absolute;
  top: 3px; left: 3px;
  content: 'dd/mm/yyyy hh:mm';
  display: inline-block;
  color: black;
} */

.width-auto {
  width: auto !important;
}

.height-auto {
  height: auto !important;
}

.width-100-percent {
  width: 100% !important;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.display-flex {
  display: flex !important;
}

.min-width-input-container {
  min-width: 200px !important;
}

/* width */
.MuiMultiSectionDigitalClock-root::-webkit-scrollbar {
  width: 6px !important;
  border-radius: 6px !important;
}

/* Track */
.MuiMultiSectionDigitalClock-root::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 6px !important;
}

/* Handle */
.MuiMultiSectionDigitalClock-root::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 6px !important;
}

/* Handle on hover */
.MuiMultiSectionDigitalClock-root::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.core-table-height {
  flex: 1;
}

.core-table-management-workflow tr td {
  padding: 0.2rem !important;
}

.core-table-management-workflow tr td input {
  padding: 0.2rem !important;
}

.core-display-flex {
  display: flex;
}

.core-align-item-center {
  align-items: center;
}

.core-justify-content-center {
  justify-content: center;
}

.core-flex-direction-row {
  flex-direction: row;
}

.core-flex-direction-colunm {
  flex-direction: column;
}

.core-background-color-red {
  background-color: #d32f2f;
}

.core-datetimepicker-container {
  background-color: #f8fafc !important;
}

.core-cursor-pointer {
  cursor: pointer;
}

.core-filter-bottom {}

.core-filter-bottom-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.core-filter-bottom .core-filter-bottom-search .core-filter-bottom-search-button {
  margin-top: 0.5rem !important;
}

.core-modal-minimize.minimize {
  z-index: -1;
  visibility: hidden;
}

.core-modal-minimize .MuiBackdrop-root.minimize {
  visibility: hidden;
}

.core-modal-minimize-header {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.75rem;
}

.core-modal-minimize-header-button-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

.core-modal-minimize-box-content {
  transition: all 0.3s ease-in-out;
}

.core-modal-minimize-box-footer {
  position: fixed;
  bottom: 1rem;
  z-index: 1000000;
  left: 50%;
  transform: translateX(-50%);
}

.core-modal-minimize-box-footer-button {
  background-color: #0ca5e9;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;
  padding: 0.1rem;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(5rem);
  visibility: hidden;
  min-width: 250px;
  text-align: center;
}

.core-modal-minimize-box-footer-button.minimize {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
}

.core-modal-minimize-box-content.minimize {
  visibility: hidden;
  opacity: 0;
  transform: translateY(75%) scale(0.1);
}

.editor-image {
  width: 100%;
}

.loader-spinner-core svg {
  display: block !important;
}

.core-control-group {
  gap: 8px;
}

.core-select-item {
  padding: 10px;
  cursor: default;
  transition: all 0.15s ease-out;
}

.core-select-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

body {
  padding-bottom: env(safe-area-inset-bottom);
}

.core-textarea {
  min-height: 7rem !important;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
  border-width: 2px;
  border-radius: 0.375rem;
  width: 100%;
  resize: vertical;
  padding: 0.5rem;
  outline: none;
}

.core-textarea.placeholder::before {
  content: attr(placeholder);
}

.core-textarea.focus::before {
  content: "";
}

.core-button-resize {
  width: 10rem !important;
}

.core-datetime-picker-resize {
  width: 15rem !important;
}

.core-input-resize {
  width: 15rem !important;
}

.core-select-resize {
  width: 15rem !important;
}

.text-18 {
  font: normal normal 600 var(--s-18);
}

.p-12-16 {
  padding: var(--px-12) var(--px-16);
}

.py-2 {
  padding: var(--px-8) 0;
}

.rs-picker-daterange-menu,
.rs-picker-menu {
  z-index: 9999 !important;
}

.core-datetimepicker-container-box input {
  /* padding: 0.63rem 0.47rem !important; */
  padding: 0px 0.5rem !important;
  width: 100% !important;
  height: 100% !important;
  color: rgb(0, 0, 0) !important;
  font-size: 0.8rem !important;
}

.core-datetimepicker-container-box .rdtPicker {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 5px !important;
  margin-top: 0.3rem !important;
}

.core-datetimepicker-container-box tfoot {
  border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.core-datetimepicker-container-box thead th {
  background-color: #ffffff !important;
}

.core-datetimepicker-container-box fieldset {
  border-color: rgba(0, 0, 0, 0.4) !important;
  border-width: 1px !important;
}

.core-datetimepicker-container-box {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  margin-top: 0.32rem !important;
}

.core-datetimepicker-container-box .MuiInputBase-root {
  height: 100% !important;
}

.core-datetimepicker-container-box .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.core-datetimepicker-container-input input {}

@media only screen and (max-width: 480px) {
  select {
    height: 2.5rem !important;
  }
}

@media only screen and (max-width: 480px) {
  .core-button {
    font-size: 0.8rem !important;
    padding: 0rem !important;
    height: 2rem !important;
  }
}

/* @media (max-width: 1024) {
  .header-user-name {
    display: none;
  }

  .header-user-logout {
    position: absolute;
    top: 35px;
    right: 0;
    width: max-content;
  }

  html {
    overflow-y: auto;
    height: max-content;
  }
} */
.MuiDrawer-paperAnchorRight {
  margin: 16px;
  padding: 16px;
  height: calc(100% - 32px) !important;
  border-radius: 8px;
}

.MuiDrawer-paperAnchorRight summary span {
  font-weight: bold;
}

@media (max-width: 7680px) {
  :root {
    --scale: 126 !important;
  }
}

@media (max-width: 4350px) {
  :root {
    --scale: 146 !important;
  }
}

@media (max-width: 3840px) {
  :root {
    --scale: 126 !important;
  }
}

@media (max-width: 2560px) {
  :root {
    --scale: 116 !important;
  }
}

@media (max-width: 1920px) {
  :root {
    --scale: 100 !important;
  }
}

@media (max-width: 1600px) {
  :root {
    --scale: 94 !important;
  }
}

@media (max-width: 770px) {
  :root {
    --scale: 86 !important;
  }
}

@media (max-width: 514px) {
  :root {
    --scale: 76 !important;
  }

  .footer button {
    padding: 8px 8px !important;
    height: auto;
    font-size: 15px;
    font-weight: 500;
    border-radius: 4px;
  }

  .core-input-resize {
    width: 100% !important;
  }

  .core-select-resize {
    width: 100% !important;
  }

  .core-datetime-picker-resize {
    width: 100% !important;
  }
}

@media (max-width: 314px) {
  :root {
    --scale: 66 !important;
  }
}