.core-task-detail-function {
    display: flex;
    gap: 12px;
}    

@media only screen and (max-width: 480px) {
    .core-task-detail-function {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }    
  }