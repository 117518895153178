@media only screen and (max-width: 480px) {
    .core-edit-report-input-form-group-button {
        align-items: start;
    }

    .core-edit-report-input-form-group-button-item {
        flex-direction: column;
    }

    .core-edit-report-input-form-group-button-item button {
        width: 100% !important;
        margin-left: 0px !important;
        margin-bottom: 0.5rem !important;
    }
}